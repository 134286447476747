import { TIMELOCALES } from "~/constants";

export const convertDate = (date: any, locale: string) => {
    if (!date) {
        return ''
    }

    const convertDate = new Date(date);
    const options: any = { month: 'short', day: '2-digit', year: 'numeric' };
    const time = TIMELOCALES.find((item: any) => {
        return item.key === locale
    })
    if (!time) {
        return ''
    }
    return convertDate.toLocaleDateString(time.local, options)

}

export const replaceLinkImageContent = (content: string, urlApi: string) => {
    if (!content) return '';

    return content.replaceAll('src="/media/', `src="${urlApi}/media/`)
}
